<template>
  <div>
    <VueAuthHeader class="header" />
    <DataPolicy :isOneTrustPublic="true" />
  </div>
</template>
<script>
import DataPolicy from '../secure/settings/DataPolicy.vue';
import VueAuthHeader from '@/components/brand/VueAuthHeader/VueAuthHeader.vue';

export default {
  name: 'OneTrustCookieSettings',
  components: {
    DataPolicy,
    VueAuthHeader,
  },
};
</script>

<style lang="scss" scoped></style>
